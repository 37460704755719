import React from 'react'
import "./Team.css"
import { Image1, Image2, Image3, Image4, Image5 } from '../../assets'
const Team = () => {
  return (
    <div className='cp__team'>
      <h1 id='cp_team_tittle'>Team</h1>
      <h2 id='cp_team_tittle_2'>Descubre a nuestro equipo</h2>
      <div className='cp__team-integrators'>
      <div className='cp__team-integrators_Orasim'>
                <img  src={Image1} alt='Orasim'/>
                <h2>Orasim</h2>
                <p>Founder</p>
            </div>
            <div className='cp__team-integrators_Bepes'>
                <img  src={Image2} alt='Bepes'/>
                <h2>Bepes</h2>
                <p>Developer</p>
            </div>
            
            <div className='cp__team-integrators_NelsonLive'>
                <img  src={Image3} alt='NelsonLive'/>
                <h2>NelsonLive</h2>
                <p>Developer</p>
            </div>
            <div className='cp__team-integrators_Disenyadora'>
                <img  src={Image4} alt='Disenyadora'/>
                <h2>Forty</h2>
                <p>Designer</p>
            </div>
            <div className='cp__team-integrators_Disenyadora'>
                <img  src={Image5} alt='Disenyadora'/>
                <h2>Hela</h2>
                <p>Designer</p>
            </div>

            
      </div>
    </div>
  )
}

export default Team
