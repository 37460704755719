import React from 'react'

const FutureEvents = () => {
  return (
    <div>
      FutureEvents
    </div>
  )
}

export default FutureEvents
