import React from 'react'
import "./Mint.css"
const Mint = () => {
  return (
    <div className='cp__mint_h1'>
      <h1 className='cp__mint_h1'>MINT NOT AVAILABLE RIGHT NOW</h1>
    </div>
  )
}

export default Mint
