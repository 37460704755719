import React, {useState} from "react";
import "./Navbar.css"
import logo from "../../assets/cryptobunnys__logo-web.png";
import "./sticky"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Outlet, Link } from "react-router-dom";


const Navbar = () =>{
const [toggleMenu, setToggleMenu] = useState(false);
return (
  <>
  <div className="cp__navbar">
    <div className="cp__navbar-links">
      <div className="cp__navbar-links_logo">
      <Link to="/">Cryptobunnys</Link>
      </div>
      <div className="cp__navbar-links_container">
          <nav>
            <ul>  
              <li>
              <a href="#">Home</a>
              </li>
              <li>
                  <a href="#collection">Collection</a>
              </li>
              <li>
                  <a href="#FAQ">FAQ</a>
              </li>
              <li>
                  <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>

      </div>
      <div className="cp_navbar-mint">
            <button type="button" onClick={() => alert('FUNCTION IN PROGRESS')} className="wallet-but"><Link to="/mint">Mint</Link></button>
      </div>
      <div className="cp__navbar-menu">
          {toggleMenu 
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)}/> 
          : <RiMenu3Line color="#EB5E28" size={27} onClick={() => setToggleMenu(true)}/>}
          {toggleMenu && (
            <div className="cp__navbar-menu_container">
              <div className="cp__navbar-menu_container-links">
                    <ul>  
                      <li>
                        <Link to="/" >Home</Link>
                      </li>
                      <li>
                          <a href="#collection">Collection</a>
                      </li>
                      <li>
                          <a href="#FAQ">FAQ</a>
                      </li>
                      <li>
                          <a href="#contact">Contact</a>
                      </li>
                    </ul>
                    <div className="cp_navbar-mint-menu">
                      <button type="button"  className="wallet-but"><Link to="/mint">Mint</Link></button>
                    </div>
              </div>
            </div>
          )}
      </div>
      
    </div>
  </div>
  <Outlet />
  </>

);


};
export default Navbar;





// const Navbar = () => {
//   return (
//     <>
//       <nav>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/blogs">Blogs</Link>
//           </li>
//           <li>
//             <Link to="/contact">Contact</Link>
//           </li>
//         </ul>
//       </nav>

//       <Outlet />
//     </>
//   )
// };

// export default Navbar;