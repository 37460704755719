import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {FutureEvents, Social, NoPage, Cryptobunny, Mint, Museum} from "./pages"
import {Navbar} from "./components"
import "./index.css"

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navbar />}>
          <Route index element={<Cryptobunny />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/social" element={<Social />} />
          <Route path="/future-events" element={<FutureEvents />} />
          <Route path="/museum" element={<Museum />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);