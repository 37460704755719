import React from 'react'
import { Model1 } from '../../components'
import "./Roadmap.css"
import { Roadmap1 } from '../../assets'
const Roadmap = () => {
  return (
    <div >
      {/* <h1 id='collection' className='cp__roadmap-tittle'>RoadMap</h1> */}
      <div className='cp__roadmap'>
      <div className='cp__roadmap-model'  >
         {/* <Model1 className-='cp_model' />   */}
         <img  id='collection'  src={Roadmap1} alt='Bepes'/>
        </div>
        <div className='cp__roadmap-text'>
          <p>CRYPTOBUNNYS ES UNA COLECCIÓN NFT QUE PRETENDE FORMAR UNA DE LAS MEJORES COMUNIDADES DESCENTRALIZADAS, DONDE A TRAVES DE EVENTOS Y DINÁMICAS TANTO FÍSICAS COMO DIGITALES, SE FOMENTE EL NETWORKING DE LA COMUNIDAD, EL APRENDIZAJE Y LA MEJORA DE LAS FINANZAS DIGITALES, DANDO PIE A EVENTOS FÍSICOS DONDE SOLO UN SELECTO GRUPO DE PERSONAS PUEDAN ACCEDER.
CON NUESTRA TESORERÍA PROPIA, DESTINADA A CRECER AÑO TRAS AÑO, SER GASTADA E INVERTIDA EN ACCIONES QUE DECIDA NUESTRA COMUNIDAD A TRAVÉS DE VOTACIÓN, SE DARÁ LUGAR A UNA ATRACTIVA Y VERDADERA DEMOCRACIA.
SIENDO PIONEROS Y AYUDANDO A EMPRESAS DEL MUNDO TRADICIONAL A DIGITALIZARSE Y ADOPTAR LA TECNOLOGÍA BLOCKCHAIN, PODREMOS OFRECER VENTAJAS EXCLUSIVAS A NUESTROS HOLDERS, DANDO PIE A EXPANDIRNOS Y COLABORAR CON OTRAS COMUNIDADES DEL SECTOR.</p>
        </div>


      </div>

    </div>
  )
}

export default Roadmap
