import React from 'react'
import './Faq.css'
const Faq = () => {
  return (
    <div>
      <div class="container" id='FAQ'>
    
        <h1 id='cp_team_tittle'>FAQ's</h1>
        <h2 id='cp_team_tittle_2'>Resolvemos tus dudas</h2>
        <div class="faq-drawer">
          <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer">¿QUE BENEFICIOS OBTENGO POR TENER UN CRYPTOBUNNY?</label>
          <div class="faq-drawer__content-wrapper">
            <div class="faq-drawer__content">
              <p>
              Los beneficios por tener un NFT de cryptobunnys van más allá de lo común, dando acceso a eventos físicos, colaboraciones y descuentos exclusivos con otras comunidades, acceso, voz y voto en nuestra DAO.              </p>
            </div>
          </div>
        </div>
        
        <div class="faq-drawer">
          <input class="faq-drawer__trigger" id="faq-drawer-2" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-2">¿COMO OBTENGO UN NFT DE CRYPTOBUNNYS?</label>
          <div class="faq-drawer__content-wrapper">
            <div class="faq-drawer__content">
              <p>
              Hay 2 formas principales de obtenerlos, minteando uno el día de lanzamiento, o en mercados secundarios una vez realizado el mint, siguenos en nuestras redes sociales para no perderte ninguna actualización.              </p>
            </div>
          </div>
        </div>
        
        <div class="faq-drawer">
          <input class="faq-drawer__trigger" id="faq-drawer-3" type="checkbox" /><label class="faq-drawer__title" for="faq-drawer-3">¿DONDE CONTACTARNOS?</label>
          <div class="faq-drawer__content-wrapper">
            <div class="faq-drawer__content">
              <p>
              Puedes contactarnos a través de <a id='cp_faq_twitter' href='https://twitter.com/_cryptobunnys' target="_blank">twitter</a>, <a id='cp_faq_instagram' href='https://www.instagram.com/_cryptobunnys/' target="_blank">instagram</a>, o a través de nuestro <a id='cp_faq_forms' href='https://forms.gle/sEy8Xxq3ChKWEgfc7' target="_blank">formulario de contacto </a>, nuestro equipo estará encantado de atender tus dudas.              </p>
            </div>
          </div>
        </div>
    
  </div>
    </div>
  )
}

export default Faq
