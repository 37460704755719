/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
  const { nodes, materials } = useGLTF("/Cryptobunnys_model_compressed.gltf");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Plane.geometry}
        material={materials["Material.003"]}
        position={[0.48454872, 0.11884093, 0.38610673]}
        scale={8.70756912}
      />
      <group position={[-8.95562267, 6.55625439, -0.87226665]}>
        <mesh
          geometry={nodes.Cube033.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Cube033_1.geometry}
          material={materials["Material.004"]}
        />
      </group>
      <group position={[-8.95562267, 6.55625439, -0.87226665]}>
        <mesh
          geometry={nodes.Cube034.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Cube034_1.geometry}
          material={materials["Material.002"]}
        />
      </group>
      <group position={[-8.95562267, 6.55625439, -0.87226665]}>
        <mesh
          geometry={nodes.Cube036.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Cube036_1.geometry}
          material={materials["Material.002"]}
        />
      </group>
      <group
        position={[2.58923531, 6.22159195, 1.87864316]}
        scale={[2.05710602, 0.12342381, 1]}
      >
        <mesh
          geometry={nodes.Cube037_1.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Cube037_2.geometry}
          material={materials["Material.029"]}
        />
        <mesh
          geometry={nodes.BagaPie_Bool_0004.geometry}
          material={nodes.BagaPie_Bool_0004.material}
          position={[1.07413137, 12.33164215, 2.81746674]}
        >
          <mesh
            geometry={nodes.BagaPie_Bool_0.geometry}
            material={nodes.BagaPie_Bool_0.material}
            position={[1.07413125, 12.33164978, 2.81746721]}
          />
        </mesh>
      </group>
      <mesh
        geometry={nodes.Circle010.geometry}
        material={materials["Material.030"]}
        position={[0.80836225, 6.24791908, 1.86309779]}
        scale={0.09228586}
      />
      <mesh
        geometry={nodes.Cube044.geometry}
        material={materials["Material.031"]}
        position={[2.63594818, 6.33369637, 1.86268866]}
        scale={0.07078953}
      />
      <mesh
        geometry={nodes.Cube045.geometry}
        material={materials["Material.031"]}
        position={[2.63594818, 6.33369637, 1.86268866]}
        scale={0.07078953}
      />
      <mesh
        geometry={nodes.Cube046.geometry}
        material={materials["Material.032"]}
        position={[2.63594818, 6.16845369, 1.86268866]}
        scale={0.07078953}
      />
      <group
        position={[7.10390759, 0.55483639, 8.71431732]}
        scale={[2.87074089, 0.4782165, 2.4288013]}
      >
        <mesh
          geometry={nodes.Cube046_1.geometry}
          material={materials.Material}
        />
        <mesh
          geometry={nodes.Cube046_2.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          geometry={nodes.Cube046_3.geometry}
          material={materials["Material.031"]}
        />
      </group>
      <group
        position={[3.17153192, 6.63023758, -7.10567617]}
        scale={[2.21868253, 1.01506877, 1.11707449]}
      >
        <mesh
          geometry={nodes.Cube004.geometry}
          material={materials["Material.034"]}
        />
        <mesh
          geometry={nodes.Cube004_1.geometry}
          material={materials["Material.004"]}
        />
      </group>
      <mesh
        geometry={nodes.BezierCurve.geometry}
        material={materials["Material.004"]}
        position={[3.07153225, 8.22671509, -6.40185118]}
        rotation={[0, 0, 0.91785503]}
        scale={0.25424415}
      />
      <mesh
        geometry={nodes.Circle011.geometry}
        material={materials["Material.036"]}
        position={[0.77813196, 5.62075806, -3.97523403]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle012.geometry}
        material={materials["Material.036"]}
        position={[1.35308814, 5.62075806, -4.23971367]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle013.geometry}
        material={materials["Material.036"]}
        position={[2.00853825, 5.62075806, -4.35470486]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle014.geometry}
        material={materials["Material.036"]}
        position={[2.39375877, 5.62075806, -4.84916735]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle015.geometry}
        material={materials["Material.036"]}
        position={[2.54899693, 5.62075806, -5.3781271]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle016.geometry}
        material={materials["Material.036"]}
        position={[3.02621055, 5.62075806, -5.70010281]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle017.geometry}
        material={materials["Material.036"]}
        position={[3.66441202, 5.62075806, -5.52761602]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle018.geometry}
        material={materials["Material.036"]}
        position={[4.22211933, 5.62075806, -5.72310114]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle019.geometry}
        material={materials["Material.036"]}
        position={[4.87181997, 5.62075806, -5.7633481]}
        scale={0.159448}
      />
      <mesh
        geometry={nodes.Circle020.geometry}
        material={materials["Material.036"]}
        position={[5.45827532, 5.62075806, -6.10832167]}
        scale={0.159448}
      />
      <group
        position={[0.5435183, 13.18105316, -6.43785572]}
        scale={[1.72575986, 1.14504266, 2.67623663]}
      >
        <mesh
          geometry={nodes.Cube049_1.geometry}
          material={materials["Material.038"]}
        />
        <mesh
          geometry={nodes.Cube049_2.geometry}
          material={materials["Material.010"]}
        />
      </group>
      <mesh
        geometry={nodes.Cube049.geometry}
        material={materials["Material.002"]}
        position={[2.26969051, 13.74131584, -4.21471071]}
        rotation={[0.65837227, 0, 0]}
        scale={[0.01291173, 0.72851771, 0.01291173]}
      />
      <mesh
        geometry={nodes.Cube050.geometry}
        material={materials["Material.002"]}
        position={[2.26969051, 14.02006054, -3.9986825]}
        rotation={[0.65837227, 0, 0]}
        scale={[0.00608879, 0.34354764, 0.00608879]}
      />
      <mesh
        geometry={nodes.BezierCurve001.geometry}
        material={materials["Material.002"]}
        position={[0.63658559, 13.61346626, -3.74771976]}
        scale={0.84017229}
      />
      <group
        position={[2.64740348, -3.40635419, 4.13992882]}
        scale={0.07158735}
      >
        <mesh
          geometry={nodes.conej_1.geometry}
          material={materials["Material.041"]}
        />
        <mesh
          geometry={nodes.conej_2.geometry}
          material={materials["Material.004"]}
        />
        <mesh
          geometry={nodes.conej_3.geometry}
          material={materials["Material.044"]}
        />
      </group>
      <mesh
        geometry={nodes.Cube028.geometry}
        material={materials["Material.012"]}
        position={[-3.6894567, 2.21314812, 6.17527533]}
        scale={[2.00333905, 2.98110366, 1.71598518]}
      />
      <group
        position={[-3.6894567, 2.21314812, 6.17527533]}
        scale={[2.00333905, 2.98110366, 1.71598518]}
      >
        <mesh
          geometry={nodes.Cube001_1.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Cube001_2.geometry}
          material={materials["Material.004"]}
        />
        <mesh
          geometry={nodes.Cube001_3.geometry}
          material={materials["Material.014"]}
        />
      </group>
      <group
        position={[7.06744432, 0.90342396, 1.01030159]}
        scale={[0.75461894, 0.78836048, 1.12518322]}
      >
        <mesh
          geometry={nodes.Cube002_1.geometry}
          material={materials["Material.007"]}
        />
        <mesh
          geometry={nodes.Cube002_2.geometry}
          material={materials["Material.004"]}
        />
        <mesh
          geometry={nodes.Cube002_3.geometry}
          material={materials["Material.019"]}
        />
      </group>
      <mesh
        geometry={nodes.Cube002.geometry}
        material={materials["Material.007"]}
        position={[4.95327139, 1.76845789, -4.57765484]}
        scale={[0.75461894, 1.69645, 1.73571837]}
      >
        <mesh
          geometry={nodes.BagaPie_Bool_1.geometry}
          material={nodes.BagaPie_Bool_1.material}
          position={[4.53189707, 1.76845825, -4.57765484]}
        />
      </mesh>
      <mesh
        geometry={nodes.Cube026.geometry}
        material={materials["Material.015"]}
        position={[-3.6894567, 2.21314812, 6.17527533]}
        scale={[2.00333905, 2.98110366, 1.71598518]}
      />
      <mesh
        geometry={nodes.Cube027.geometry}
        material={materials["Material.012"]}
        position={[-3.6894567, 2.21314812, 6.17527533]}
        scale={[2.00333905, 2.98110366, 1.71598518]}
      />
      <mesh
        geometry={nodes.Cube029.geometry}
        material={materials["Material.012"]}
        position={[-3.6894567, 2.21314812, 6.17527533]}
        scale={[2.00333905, 2.98110366, 1.71598518]}
      />
      <mesh
        geometry={nodes.Cube030.geometry}
        material={materials["Material.012"]}
        position={[-3.6894567, 2.21314812, 6.17527533]}
        scale={[2.00333905, 2.98110366, 1.71598518]}
      />
      <mesh
        geometry={nodes.Cube031.geometry}
        material={nodes.Cube031.material}
        position={[6.88496017, 0.1332587, -3.38687921]}
        scale={[0.25449479, 0.04373043, 0.25449479]}
      />
      <mesh
        geometry={nodes.Text.geometry}
        material={materials["Material.004"]}
        position={[7.86536217, 2.16935968, -3.06431818]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={1.11147332}
      />
      <mesh
        geometry={nodes.Text001.geometry}
        material={materials["Material.018"]}
        position={[7.95865011, 1.6679889, 1.99119437]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.4458628}
      />
      <mesh
        geometry={nodes.Plane001.geometry}
        material={materials["Material.020"]}
        position={[7.06443071, 0.72986257, 2.02267623]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.71229607, 0.93472302, 0.58552986]}
      />
      <mesh
        geometry={nodes.Text002.geometry}
        material={materials["Material.022"]}
        position={[10.28734207, -0.48184189, 3.51935363]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.97342396}
      />
      <mesh
        geometry={nodes.Text003.geometry}
        material={materials["Material.021"]}
        position={[10.43861866, -0.44284526, -1.06786788]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={1.13146794}
      />
      <mesh
        geometry={nodes.Text004.geometry}
        material={materials["Material.022"]}
        position={[3.54501319, -0.48626304, 12.98895359]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.11147332}
      />
      <mesh
        geometry={nodes.Text005.geometry}
        material={materials["Material.022"]}
        position={[-6.89116907, -0.43309376, 10.01084423]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.11147332}
      />
      <mesh
        geometry={nodes.Plane002.geometry}
        material={materials["Material.035"]}
        position={[-2.37173104, 5.61701345, -2.28028989]}
        scale={[8.58295155, 6.84763432, 6.68945694]}
      />
      <mesh
        geometry={nodes.Text006.geometry}
        material={materials["Material.022"]}
        position={[0.22968015, 32.62040329, 14.35119057]}
        rotation={[1.11105917, 0.41761306, -0.68631047]}
        scale={[4.18892431, 4.18892479, 4.18892527]}
      />
      <mesh
        geometry={nodes.Text007.geometry}
        material={materials["Material.004"]}
        position={[6.36866188, 0.84864414, 11.27720928]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.26618686, 0.26618686, 0.25380701]}
      />
      <mesh
        geometry={nodes.Text008.geometry}
        material={materials["Material.021"]}
        position={[-0.47075546, 7.00925398, 3.69269037]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={1.15579379}
      />
      <mesh
        geometry={nodes.Text009.geometry}
        material={materials["Material.021"]}
        position={[2.77924871, 7.9844327, -8.50539112]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.11911416}
      />
      <mesh
        geometry={nodes.Text010.geometry}
        material={materials["Material.021"]}
        position={[-2.17917633, 15.1710434, -1.48019969]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.96979988}
      />
      <mesh
        geometry={nodes.Text011.geometry}
        material={materials["Material.021"]}
        position={[-9.16323376, 12.71523762, 6.7830348]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={0.94499213}
      />
      <mesh
        geometry={nodes.Plane004.geometry}
        material={materials["Material.035"]}
        position={[0.48002264, 12.0436554, -5.14014864]}
        scale={[2.96112227, 2.28216147, 4.53924274]}
      />
    </group>
  );
}

useGLTF.preload("/Cryptobunnys_model_compressed.gltf");
