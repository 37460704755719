import React from 'react'
import "./Cryptobunnys.css"
import { Header, Team, Roadmap, Faq, Contact, Footer } from '../../containers';
import { Giveaway, SocialMedia } from '../../components';
const Cryptobunny = () => {
  return (
    <div className='cp_all'>
      <Header/>
      <Roadmap />
      <Team />
      <Faq />
      <Contact />
      <Footer />
    </div>
  )
}

export default Cryptobunny
