import React from 'react'
import { Tiktok, Instagram, Twitter, Discord } from '../../assets';
import "./footer.css"
const Footer = () => {
  return (
    <div className='cp_footer'>
        <div className="cp__footer-instagram">
          <a href="https://www.instagram.com/_cryptobunnys/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="instagram" />
          </a>
        </div>
        <div className="cp__footer-discord">
          <a href="https://twitter.com/_cryptobunnys" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="twitter" />
          </a>
        </div>
        <div className="cp__footer-Twitter">
          <a href="https://discord.gg/NkgQ5W3gwr" target="_blank" rel="noreferrer">
            <img src={Discord} alt="discord" />
          </a>
        </div>
        <div className="cp__footer-tiktok">
          <a href="https://www.tiktok.com/@_cryptobunnys" target="_blank" rel="noreferrer">
            <img src={Tiktok} alt="tiktok" />
          </a>
        </div>
    </div>
  )
}

export default Footer
