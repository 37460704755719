import React from 'react'
import './Contact.css'
const Contact = () => {
  return (
    <div>
      <div className='cp__contact' id='contact'>
          <h1 className='cp__contact_h1'>Contact</h1> 
           <p className='cp_contact_p'><a href='https://forms.gle/sEy8Xxq3ChKWEgfc7' target="_blank">Formulario de contacto</a></p>
      </div>
    </div>
  )
}

export default Contact
