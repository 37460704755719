const countdown = () =>{
    const countDate = new Date("Aug 25, 2023, 00:00:00").getTime()
    const now = new Date().getTime();
    const gap = countDate - now;
   
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
   
    const textDay = Math.floor(gap / day);
    const textHour = Math.floor((gap % day) / hour)
    const textMinute = Math.floor((gap % hour) / minute)
    const textSecond = Math.floor((gap % minute) / second)
    
    
    const hour0 = '0' + textHour
    const day0 = '0' + textDay
    const minute0 = '0' + textMinute
    const second0 = '0' + textSecond
    const zeroo = '00'
    /*
    if(textDay === 0){
      document.querySelector('.cp__countdown_days-day').innerHTML = zeroo;
   
    }
    if( textDay < 10){
       document.querySelector('.cp__countdown_seconds-seconds').innerHTML = day0;
   
    }else{
       document.querySelector('.cp__countdown_days-day').innerHTML = textDay;
    }
    if( textHour < 10){
       document.querySelector('.cp__countdown_hour-hour').innerHTML = hour0;
   
  }else{
     document.querySelector('.cp__countdown_hour-hour').innerHTML = textHour;
  }
  if( textMinute < 10){
     document.querySelector('.cp__countdown_minute-minute').innerHTML = minute0;
   
  }else{
     document.querySelector('.cp__countdown_minute-minute').innerHTML = textMinute;
  }
    if( textSecond < 10){
       document.querySelector('.cp__countdown_seconds-seconds').innerHTML = second0;
   
    }else{
     document.querySelector('.cp__countdown_seconds-seconds').innerHTML = textSecond;
    }

    */


 
   if(textDay === 0){
      document.querySelector('.cp__countdown_days-day').innerHTML = "?";
   
    }
    if( textDay < 10){
       document.querySelector('.cp__countdown_seconds-seconds').innerHTML =  "?";
   
    }else{
       document.querySelector('.cp__countdown_days-day').innerHTML =  "?";
    }
    if( textHour < 10){
       document.querySelector('.cp__countdown_hour-hour').innerHTML =  "?";
   
    }else{
       document.querySelector('.cp__countdown_hour-hour').innerHTML =  "?";
    }
    if( textMinute < 10){
       document.querySelector('.cp__countdown_minute-minute').innerHTML =  "?";
   
    }else{
       document.querySelector('.cp__countdown_minute-minute').innerHTML =  "?";
    }
    if( textSecond < 10){
       document.querySelector('.cp__countdown_seconds-seconds').innerHTML =  "?";
   
    }else{
       document.querySelector('.cp__countdown_seconds-seconds').innerHTML =  "?";
    } 
   
   
   
   
   if(gap < 100000) {
   console.log('finished')
   }
   
   
   }
   setInterval(countdown, 1000)