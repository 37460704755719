import React from 'react'
import {Countdown, SocialMedia } from '../../components'

const Header = () => {
  return (
    <div>
      <Countdown id='header'/>
      <SocialMedia />
    </div>
  )
}

export default Header
