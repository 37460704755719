import React from 'react'
import { Tiktok, Instagram, Twitter, Discord } from '../../assets';
import "./socialmedia.css"

const SocialMedia = () => {
  return (
    <div className="cp__socialmedia">
      <footer>
      <h1>Social </h1>
      <div className="cp__socialmedia-svg">
        <div className="cp__socialmedia-instagram">
          <a href="https://www.instagram.com/_cryptobunnys/" target="_blank" rel="noreferrer">
            <img src={Instagram} alt="instagram" />
          </a>
        </div>
        <div className="cp__socialmedia-discord">
          <a href="https://twitter.com/_cryptobunnys" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="twitter" />
          </a>
        </div>
{/*         <div className="cp__socialmedia-Twitter">
          <a href="https://discord.gg/NkgQ5W3gwr" target="_blank" rel="noreferrer">
            <img src={Discord} alt="discord" />
          </a>
        </div> */}
        <div className="cp__socialmedia-tiktok">
          <a href="https://www.tiktok.com/@_cryptobunnys_?_t=8ekOk1bBXHd&_r=1" target="_blank" rel="noreferrer">
            <img src={Tiktok} alt="tiktok" />
          </a>
        </div>
      </div>
      </footer>
      
    </div>
  );
}

export default SocialMedia
