import React from 'react'
import "./Social.css"
import { SocialMedia } from '../../components'
const Social = () => {
  return (
    <div>
    <SocialMedia />
    </div>
  )
}

export default Social
