import React from 'react'
import './NoPage.css'
const NoPage = () => {
  return (
    <div>
      <h1 className='cp_nopage-h1'>404</h1>
    </div>
  )
}

export default NoPage
