import React from 'react'

import "./Countdown12.js"
import "./Countdown1.css"
const Countdown = () => {
    return (
        <div className="counter">
          <div className="cp__countdown">
            <div className="cp__countdown_days">
              <h3 className="cp__countdown_days-day">?</h3>
              <h3 className="cp__countdown_days-day-day">Days</h3>
            </div>
            <div className="cp__countdown_hour">
              <h3 className="cp__countdown_hour-hour">?</h3>
              <h3>Hours</h3>
            </div>
            <div className="cp__countdown_minute">
              <h3 className="cp__countdown_minute-minute">?</h3>
              <h3>Minutes</h3>
            </div>
            <div className="cp__countdown_seconds">
              <h3 className="cp__countdown_seconds-seconds">?</h3>
              <h3>Seconds</h3>
            </div>
    
          </div>
    {/* 
            <a href="#events"><p>List of events</p></a> */}
        </div>
    
      );
}

export default Countdown
