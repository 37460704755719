import React from 'react'
import { Suspense } from 'react'
import {Canvas} from "react-three-fiber"
import Model from "./Cryptobunnys_model_compressed"
import "./model.css"
import {OrbitControls} from '@react-three/drei'

const Model1 = () => {
  return (
    <div>
      <div className='cp__model'>
      <Canvas camera={{zoom: 1, position:[30, 30, 30]}}>

        <ambientLight intensity={0.5}></ambientLight>
        <pointLight  position={[35, 35, 0]} />
        {/* <pointLight  position={[-35, 35, 0]} /> */}
        <Suspense fallback={null}>
         <Model />
        </Suspense>
        <OrbitControls target={[1,10,0]} />
      </Canvas>
      </div>

    </div>
  )
}

export default Model1
